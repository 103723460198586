<template>
    <div class="Integral-management">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>积分管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card" style="min-height:100%">
            <div class="add">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openIntegralBox">添加积分类型</el-button>
            </div>
            <div class="Integral-list">
                <el-table
                    :data="IntegralList"
                    border
                    stripe
                    :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                    style="width: 100%">
                    <el-table-column
                         align="center"
                        prop="typeName"
                        label="积分类型">
                    </el-table-column>
                    <el-table-column
                         align="center"
                        prop="number"
                        label="积分值">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="isPlus"
                        label="用途类型">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.isPlus" type="success">加</el-button>
                            <el-button v-else type="danger" >减</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="createTime"
                        label="创建时间">
                        <template slot-scope="scope">
                            <span>{{scope.row.createTime.split(' ')[0]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="操作">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" circle  @click="editIntegral(scope.row)"></el-button>
                            <el-button icon="el-icon-delete" circle   @click="deleteIntegral(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-dialog
                :title="isType"
                :before-close="handleClose"
                :visible.sync="IntegralVisible"
                width="500px">
                <div class="form-management">
                    <el-form ref="integralFormRef" :model="integralForm" :rules="integralRules" label-width="110px">
                        <el-form-item label="积分类型" prop="type">
                            <el-select v-model="integralForm.type" placeholder="请选择积分类型" size="small" style="width:300px" :disabled="type">
                                <el-option
                                v-for="item in IntegralTypes"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="请填写积分值" prop="number">
                             <el-input v-model="integralForm.number" size="small" style="width:300px" type="number" min="0"></el-input>
                        </el-form-item>
                        <el-form-item label="用途类型" prop="isPlus">
                            <el-select v-model="integralForm.isPlus" placeholder="请选择积分用途类型" size="small" style="width:300px" :disabled="type">
                                <el-option
                                v-for="item in plusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="请填写积分描述">
                             <el-input v-model="integralForm.detail" size="small" style="width:300px" type="textarea"></el-input>
                        </el-form-item> -->
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleClose" class="gary">取 消</el-button>
                    <el-button  class="mybotton white" @click="saveIntegral">确 定</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
import companyRequest from '../../api/company'
export default {
    mounted () {
        this.getAllIntegralType()
        this.getIntegralList()  
    },
    data() {
        return {
            IntegralVisible: false, //添加编辑积分类型模态框
            type: false, //切换模态框动态标题
            IntegralTypes: [], //积分类型列表
            IntegralList: [], //积分列表
            integralForm: { //添加/编辑积分
                id: '', 
                number: '', //积分值
                // detail: '', //描述
                type: '', // 积分类型
                isPlus: '' //用途类型 0减 1加 
            },
            plusList: [
                {id: 0, name: '减'},
                {id: 1, name: '加'}
            ],
            integralRules: { //验证规则
                type: [ { required: true, message: '请选择积分类型', trigger: 'change' }],
                number: [ { required: true, message: '请填写积分值', trigger: 'bulr' }],
                isPlus: [ { required: true, message: '请选择积分类型', trigger: 'change' }],
            }
        }
    },
    methods: {
        openIntegralBox() {
            this.IntegralVisible = true
            // if (this.type) {
            //     this.integralForm.id = ''
            //     this.integralForm.type = ''
            //     this.integralForm.number = ''
            //     this.integralForm.detail = ''
            //     this.integralForm.isPlus = ''
            //     this.$nextTick(() => {
            //         this.$refs.integralFormRef.resetFields()
            //         this.$refs.integralFormRef.clearValidate()
            //     })
            // } else {
            //     this.IntegralVisible = true
            // }
            // if (!this.type) {
            //     this.IntegralVisible = true
            // }
        },
        //获取积分类型
        getAllIntegralType() {
           companyRequest.getAllIntegralType().then((res) => {
               if (res.code === 200) {
                   this.IntegralTypes = res.data
               }
           }) 
        },
        //获取积分列表
        getIntegralList() {
            companyRequest.getIntegralList().then((res) => {
                if (res.code === 200) {
                    this.IntegralList = res.data
                }
            }) 
        },
        //编辑
        editIntegral(row) {
            // console.log('编辑', row)
            this.type =true
            this.integralForm = JSON.parse(JSON.stringify(row))
            if (this.integralForm.isPlus) {
                this.integralForm.isPlus = 1
            } else {
                this.integralForm.isPlus = 0
            }
            this.IntegralVisible = true
        },
        //删除
        deleteIntegral(row) {
            // console.log('删除', row)
            this.$confirm('此操作将永久删除该积分类型, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    companyRequest.deleteIntegral({id: row.id}).then((res) => {
                        if (res.code === 200) {
                            this.$message.success('删除成功')
                            this.getIntegralList()
                        }
                    }).catch((err) => {
                        this.$message.error(err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })        
                })
        },
        //保存或修改积分
        saveIntegral() {
            this.$refs.integralFormRef.validate((valid) => {
                if (valid) {
                    companyRequest.addIntegral(this.integralForm).then((res) => {
                        if (res.code === 200) {
                            this.$message.success('操作成功~')
                            this.type = false
                            this.getIntegralList()
                            this.integralForm.id = ''
                            this.integralForm.type = ''
                            this.integralForm.number = ''
                            // this.integralForm.detail = ''
                            this.integralForm.isPlus = ''
                            this.$nextTick(() => {
                                this.$refs.integralFormRef.resetFields()
                                this.$refs.integralFormRef.clearValidate()
                            })
                            this.IntegralVisible = false
                        }
                    }).catch((err) => {
                        this.$message.error(err)
                        this.type = false
                        this.integralForm.id = ''
                        this.integralForm.type = ''
                        this.integralForm.number = ''
                        // this.integralForm.detail = ''
                        this.integralForm.isPlus = ''
                        this.$nextTick(() => {
                            this.$refs.integralFormRef.resetFields()
                            this.$refs.integralFormRef.clearValidate()
                        })
                        this.IntegralVisible = false
                        this.type = false
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleClose() {
            this.integralForm.id = ''
            this.integralForm.type = ''
            this.integralForm.number = ''
            // this.integralForm.detail = ''
            this.integralForm.isPlus = ''
            this.$nextTick(() => {
                this.$refs.integralFormRef.resetFields()
                this.$refs.integralFormRef.clearValidate()
            })
            this.IntegralVisible = false
            setTimeout(() => {
                this.type = false
            }, 600)
        }
    },
    computed: {
        isType() {
            return this.type? '修改' : '增加'
        }
    }
}
</script>
<style lang="scss" scoped>
    .Integral-management{
        height: 100%;
        .el-card{
            margin-top: 20px;
        }
        .add{
            display: flex;
            padding-bottom: 10px; 
            justify-content: flex-end;
            .addbutton{
                color: #fff;
            }
            border-bottom: 1px solid rgb(245, 243, 243);
        }
        .white{
            color: #fff;
        }
        .gary{
            color: #000;
            &:hover{
                color: #000 !important;
                border: 1px solid #eee !important;
                background: #fff !important;
            }
        }
    }
</style>